<template>
  <section class="tables">
    
    <div class="page-header">
      <h3 class="page-title">Flat Rate</h3>
      <nav aria-label="breadcrumb">
        <div class="float-right">
          <router-link
            class="btn btn btn-add btn-primary"
            :to="`/settings/add-flat-rate`"
            >Add New
          </router-link>
        </div>
      </nav>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-title">
            <div class="row">
              <div class="col-12 col-md-3 col-lg-32">
                <select
                  class="custom-select form-control"
                  @change="sortByType"
                  v-model="type"
                >
                  <option value="" selected>Select Status</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>
            </div>
          </div>
          <div class="table-responsive pb-2">
            <table class="table table-centered table-nowrap">
              <thead>
                <tr>
                  <th>Rate</th>
                  <th>Min Weight</th>
                  <th>Max Weight</th>
                  <th>Min Value</th>
                  <th>Max Value</th>
                  <th>Countries</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="flatRate in flatRate" :key="flatRate.id">
                  <td>
                    {{ flatRate.rate }}
                  </td>
                  <td>
                    {{ flatRate.min_weight }}
                  </td>
                  <td>
                    {{ flatRate.max_weight }}
                  </td>
                  <td>
                    {{ flatRate.min_value }}
                  </td>
                  <td>
                    {{ flatRate.max_value }}
                  </td>
                  <td>
                    <span
                      v-for="countries in flatRate.countries"
                      :key="countries.id"
                    >
                      {{ countries.name }},</span
                    >
                  </td>

                  <td>
                    <toggle-button
                      @change="onToggleChange(flatRate.id, $event)"
                      v-model="flatRate.active"
                      :labels="{ checked: 'true', unchecked: 'false' }"
                    />
                  </td>
                  <td>
                    <router-link
                      class="mr-3 text-info"
                      :to="`/settings/edit-flat-rate/${flatRate.id}`"
                    >
                      <i class="mdi mdi-pencil font-size-18"></i>
                    </router-link>
                    <a
                      v-on:click="deleteFile(flatRate.id)"
                      class="text-primary"
                    >
                      <i class="mdi mdi-window-close font-size-18"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="col-12 pt-3 pb-0 mb-0 text-center"
              v-if="flatRate == ''"
            >
              No data found!
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { setingsApi } from "../../../api";

export default {
  name: "flat-rate",
  props: {
    edit: {
      active: Boolean,
      name: String,
      description: String,
      image_id: Number,
    },
  },
  components: {
    
  },
  data() {
    return {
      flatRate: this.edit,
      
      type: "",
      body: ""
    };
  },
  mounted() {
    this.getFlatRate();
  },
  methods: {
    async getFlatRate() {
      
      const { data } = await setingsApi.getFlatRate();
      this.flatRate = data.data.data;
      this.flatRate.forEach((val) => {
        if (val.active === 1) {
          val.active = true;
        } else {
          val.active = false;
        }
      });
      
    },
    async onToggleChange(id, event) {
      this.body = { active: event.value, };
      
      const data = await setingsApi.updateFlatRate(id, {
        ...this.body,
      });
      
      this.getFlatRate();
      if (data.status == 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.messages,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.messages,
        });
      }
    },
    async sortByType() {
      if (this.type === "") {
        this.getFlatRate();
      } else {
        
        const { data } = await setingsApi.sortByIdStatus(this.type);
        this.flatRate = data.data.data;
        
      }
    },
    deleteFile(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          
          const response = setingsApi.deleteFlatRate(id).then((response) => {
            
            if (response.data.code == 200) {
              this.getFlatRate();
              if (result.value) {
                this.$swal(
                  "Deleted!",
                  "Your file has been deleted.",
                  "success"
                );
              }
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped></style>
